import CryptoJS from 'crypto-js';
import { AuthSession } from 'services/auth';

export const getTokenFromCryptedStorage = () => {
  try {
    const item = localStorage.getItem('session');

    if (item) {
      const bytes = CryptoJS.AES.decrypt(
        item,
        process.env.REACT_APP_ENCRYPTION_KEY!,
      );
      const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
      return decryptedData
        ? (JSON.parse(decryptedData) as AuthSession).access_token
        : '';
    }
    return '';
  } catch (error) {
    return '';
  }
};
